.form{
  &-check{
    &-input{
      cursor: pointer;
    }

    &-danger{
      &:checked{
        border-color: $red;
        background-color: $red;
      }
    }

    &-success{
      &:checked{
        border-color: $green;
        background-color: $green;
      }
    }

    &-warning{
      &:checked{
        border-color: $orange;
        background-color: $orange;
      }
    }

    &-default{
      &:checked{
        border-color: $default-bg-text;
        background-color: $default-bg-text;
      }
    }
  }

  &-float{
    position: relative;

    label{
      position: absolute;
      top: 0;
      left: rem(10);
      line-height: rem(16);
      z-index: 1;
      transform: scale(.75) translateY(-.75rem);

      &::after{
        content: '';
        position: absolute;
        top: rem(-5);
        right: rem(-8);
        bottom: rem(-5);
        left: rem(-8);
        background-color: $white;
        z-index: -1;
      }
    }

    .form-control,
    .form-select{
      padding-top: calc(.375rem + rem(5));
    }
  }
}
