$space: (
  'm': 'margin',
  'mt': 'margin-top',
  'mb': 'margin-bottom',
  'ml': 'margin-left',
  'ms': 'margin-left',
  'mr': 'margin-right',
  'me': 'margin-right',
  'mx': 'margin-inline',
  'my': 'margin-block',
  'p': 'padding',
  'pt': 'padding-top',
  'pb': 'padding-bottom',
  'pl': 'padding-left',
  'ps': 'padding-left',
  'pr': 'padding-right',
  'pe': 'padding-right',
  'px': 'padding-inline',
  'py': 'padding-block'
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $property in $space {
      .#{$name}#{$infix} {
        @for $i from 0 through 5 {
          &-#{$i} {
            #{$property}: $i * .25rem !important;
          }
        }
      }
    }
  }
}
