.ui-datepicker{
  border-radius: rem(5) !important;
  border: 0 !important;

  .ui-datepicker{
    &-title{
      select{
        &.ui-datepicker{
          &-month,
          &-year{
            margin: 0 rem(5);
            padding: rem(4) rem(8);
            border-radius: rem(4);
            border: 0;
          }
        }
      }
    }

    &-header,
    .ui-widget-header{
      background-color: rgba($default-bg-text-rgb, .5) !important;
    }

    &-prev{
      color: transparent !important;

      &::before{
        content:'\f078' !important;
        font: 900 rem(20) $awesome !important;
      }
    }

    &-next{
      color: transparent !important;

      &::before{
        content:'\f077' !important;
        font: 900 rem(20) $awesome !important;
      }
    }
  }

  td.ui-datepicker-current-day,
  td .ui-state-hover,
  thead{
    background-color: rgba($default-bg-text-rgb, .7) !important;
  }

  td{
    border: 0 !important;

    &.ui-datepicker-today{
      background-color: rgba($default-bg-text-rgb, .15) !important;
    }

    &.ui-datepicker-other-month{
      background-color: rgba($black, .15) !important;
    }
  }
}
