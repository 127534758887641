$url: '../../assets/fonts/';

$fonts: (
  'Regular': (
    'weight': normal,
    'style': normal
  ),
  'Italic': (
    'weight': normal,
    'style': italic
  ),
  'Light': (
    'weight': 300,
    'style': normal
  ),
  'LightItalic': (
    'weight': 300,
    'style': italic
  ),
  'SemiBold': (
    'weight': 600,
    'style': normal
  ),
  'SemiBoldItalic': (
    'weight': 600,
    'style': italic
  ),
  'Bold': (
    'weight': bold,
    'style': normal
  ),
  'BoldItalic': (
    'weight': bold,
    'style': italic
  )
);

@mixin font($fontName, $path, $style) {
  @each $variation, $properties in $fonts {
    @if $variation == $style {
      @font-face {
        font-family: $fontName;
        src: url($url + $path + '/' + $path + '-' +  $variation + '.ttf') format('truetype');
        font-weight: map-get($properties, weight);
        font-style: map-get($properties, style);
        font-display: swap;
      }
    }
  }
}

// open sans
@include font('Open sans', 'OpenSans', 'Regular');
@include font('Open sans', 'OpenSans', 'Italic');
@include font('Open sans', 'OpenSans', 'SemiBold');
@include font('Open sans', 'OpenSans', 'SemiBoldItalic');
@include font('Open sans', 'OpenSans', 'Bold');
@include font('Open sans', 'OpenSans', 'BoldItalic');

$opensans: 'Open sans', Arial, sans-serif;
$awesome: 'Font Awesome 6 Free';
