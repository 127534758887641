@each $breakpoint in map-keys($grid-breakpoints){
  @include media-breakpoint-up($breakpoint){
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .bg#{$infix}{
      @each $name, $hex in $colors{
        &-#{$name}{
          background-color: $hex !important;

          @for $i from 1 through 9{
            &-#{$i}{
              background-color: rgba($hex, .1 * $i) !important;
            }
          }
        }
      }

      &-linear{
        &-1{
          background-image: linear-gradient(to right, #7047f1, #a2b9fe);
        }

        &-2{
          background-image: linear-gradient(to right,#eb831c, #ffe993);
        }

        &-3{
          background-image: linear-gradient(to right, #b344ff, #ffb6ff);
        }

        &-4{
          background-image: linear-gradient(to right, #f467b9, #ffb29f);
        }

        &-5{
          background-image: linear-gradient(to left, rgba(42, 240, 228, .4) 0%, rgba(140, 51, 255, .4) 37.5%, rgba(255, 39, 118, .4) 68.75%, rgba(255, 188, 15, .4) 100%);
        }
      }
    }
  }
}
