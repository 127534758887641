// align-items
$align-items: (
  start: flex-start,
  end: flex-end,
  center: center,
  baseline: baseline,
  stretch: stretch
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .align-items#{$infix}{
      @each $class, $value in $align-items {
        &-#{$class} {
          align-items: $value !important;
        }
      }
    }
  }
}

// justify-content
$justify-content: (
  start: flex-start,
  end: flex-end,
  center: center,
  between: space-between,
  around: space-around
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .justify-content#{$infix}{
      @each $class, $value in $justify-content {
        &-#{$class} {
          justify-content: $value !important;
        }
      }
    }
  }
}
