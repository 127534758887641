@each $breakpoint in map-keys($grid-breakpoints){
  @include media-breakpoint-up($breakpoint){
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .z-index#{$infix}{
      @for $i from 0 through 9{
        &-#{$i}{
          z-index: $i !important;
        }
      }
    }
  }
}
