.text{
  &-start,
  &-left {
    text-align: left !important;
  }

  &-center {
    text-align: center !important;
  }

  &-end,
  &-right {
    text-align: right !important;
  }

  &-default{
    color: $default-bg-text !important;
  }

  @each $name, $hex in $colors{
    &-#{$name}{
      color: $hex !important;
    }
  }
}
