.dropdown{
  &-toggle{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;

    &::after{
      margin-left: rem(12);
    }
  }

  &-menu{
    display: block;
    margin-top: rem(5);
    padding: rem(8);
    border: 0;
    z-index: 2;

    &-end{
      right: 0;
    }
  }

  &-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(8);

    &::after{
      content: '\f061';
      margin-left: rem(45);
      opacity: 0;
      font-weight: 600;
      font-family: $awesome;
      transform: translateX(rem(-10));
      transition: all .3s;
    }

    &:hover,
    &:focus-visible{
      &::after{
        opacity: 1;
        transform: none;
      }
    }
  }

  &-backdrop{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    backdrop-filter: blur(rem(2));
    background-color: rgba($black, .3);
    z-index: 1;
  }
}
