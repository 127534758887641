$cursor: (
  default,
  auto,
  pointer,
  grab,
  grabbing,
  zoom-in,
  zoom-out,
  help,
  text,
  move,
  not-allowed,
  crosshair,
  e-resize,
  ne-resize,
  nw-resize,
  n-resize,
  se-resize,
  sw-resize,
  s-resize,
  w-resize
);

@each $breakpoint in map-keys($grid-breakpoints){
  @include media-breakpoint-up($breakpoint){
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .cursor#{$infix}{
      @each $name in $cursor{
        &-#{$name}{
          cursor: $name !important;
        }
      }
    }
  }
}
