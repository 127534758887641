.header{
  padding: rem(8);

  .btn{
    &-brand{
      padding: 0;
      color: var(--color-bg-default-text);
      font-weight: bold;
      font-size: rem(26);
      line-height: 0;
      letter-spacing: rem(.4);
      border: 0;
      background-color: transparent;

      &:hover,
      &:focus-visible{
        color: var(--color-bg-default-text);
        background-color: transparent;
      }
    }
  }

  &-bottom{
    padding: rem(8) rem(20);
    position: fixed;
    bottom: rem(10);
    left: 50%;
    color: $white;
    border-radius: rem(50);
    background-color: $black;
    transform: translateX(-50%);
    transition: all .3s;
    z-index: 5;

    .btn{
      &-brand{
        color: $white;
        font-size: rem(20);

        &:hover,
        &:focus-visible{
          color: $white;
        }
      }
    }
  }
}
