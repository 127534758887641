.ant{
  &-table{
    color: $default-bg-text;
    border-radius: $rounded;
    background-color: $default-bg-card;

    &-tbody{
      tr{
        td{
          border-bottom-color: rgba($default-bg-text-rgb, .1);
        }

        &:hover{
          td{
            background: rgba($default-rgb, .1) !important;
          }
        }

        &:last-child{
          td{
            border-bottom: 0;
          }
        }
      }
    }

    &-striped{
      tbody{
        tr:nth-child(even){
          td{
            background-color: rgba($default-rgb, .05);
          }
        }
      }
    }

    &-borderless{
      tbody{
        tr{
          td{
            border-bottom: 0 !important;
          }
        }
      }
    }

    &-cursor{
      tbody{
        tr{
          cursor: pointer;
        }
      }
    }
  }
}
