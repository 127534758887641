:root {
  @include default-theme;
}

*{
  box-sizing:border-box;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

body{
  margin: 0;
  color: var(--color-bg-default-text);
  font: 400 $pxdefault $opensans;
  background-color: var(--color-bg-default);
}

img{
  max-width: 100%;
}

a{
  // color: var(--color-default-text);

  &:hover,
  &:focus-visible{
    // color: var(--color-default-text-hover);
  }
}
