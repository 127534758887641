@import "themes/background";
@import "themes/color";
@import "themes/rounded";
@import "themes/size";

// Theme utilisé par défaut
@mixin default-theme {
  @each $name, $hex in map-get($theme-background, 'default') {
    --color-bg-#{$name}: #{$hex};
    --color-bg-#{$name}-rgb: #{toRGB($hex)};
  }

  @each $name, $hex in map-get($theme-color, 'default') {
    --color-#{$name}: #{$hex};
    --color-#{$name}-rgb: #{toRGB($hex)};
  }

  @each $name, $size in map-get($theme-rounded, 'default') {
    --rounded-#{$name}: #{$size};
  }

  @each $name, $size in map-get($theme-size, 'default') {
    --size-#{$name}: #{$size};
  }
}

// Couleurs de fond
@each $name, $colors in $theme-background {
  .theme-bg-#{$name} {
    @each $name, $hex in $colors {
      --color-bg-#{$name}: #{$hex};
      --color-bg-#{$name}-rgb: #{toRGB($hex)};
    }
  }
}

// Couleurs de texte
@each $name, $colors in $theme-color {
  .theme-color-#{$name} {
    @each $name, $hex in $colors {
      --color-#{$name}: #{$hex};
      --color-#{$name}-rgb: #{toRGB($hex)};
    }
  }
}

// Tailles de police
@each $name, $size in $theme-size {
  .theme-size-#{$name} {
    @each $name, $size in $size {
      --size-#{$name}: #{$size};
    }
  }
}

// Tailles de bordure
@each $name, $size in $theme-rounded {
  .theme-rounded-#{$name} {
    @each $name, $size in $size {
      --rounded-#{$name}: #{$size};
    }
  }
}
