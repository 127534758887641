$black: #000;
$white: #fff;

$blue: #0243a3;
$blue-light: #f2f8fe;
$green: #03a10e;
$green-light: #deefe0;
$grey: #f5f5f7;
$grey-300: #efefef;
$grey-200: #fafafa;
$orange: #f87539;
$orange-light: #fff1ec;
$pink: #e15e70;
$red: #c00;
$yellow: #f8b30e;
$yellow-light: #feefcf;

// color
$default: var(--color-default);
$default-rgb: var(--color-default-rgb);
$default-hover: var(--color-default-hover);
$default-hover-rgb: var(--color-default-hover-rgb);
$default-text: var(--color-default-text);
$default-text-rgb: var(--color-default-text-rgb);
$default-text-hover: var(--color-default-text);
$default-text-hover-rgb: var(--color-default-text-rgb);
$default-secondary: var(--color-default-secondary);
$default-secondary-rgb: var(--color-default-secondary-rgb);
$default-secondary-hover: var(--color-default-secondary-hover);
$default-secondary-hover-rgb: var(--color-default-secondary-hover-rgb);
$default-secondary-text: var(--color-default-secondary-text);
$default-secondary-text-rgb: var(--color-default-secondary-text-rgb);
$default-secondary-text-hover: var(--color-default-secondary-text);
$default-secondary-text-hover-rgb: var(--color-default-secondary-text-rgb);

//background
$default-bg: var(--color-bg-default);
$default-bg-rgb: var(--color-bg-default-rgb);
$default-bg-text: var(--color-bg-default-text);
$default-bg-text-rgb: var(--color-bg-default-text-rgb);
$default-bg-card: var(--color-bg-default-card);
$default-bg-card-rgb: var(--color-bg-default-card-rgb);

$colors: (
  'black': $black,
  'white': $white,
  'default': $default
);
