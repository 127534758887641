$list-rounded: (
  'top',
  'left',
  'right',
  'bottom',
  'null'
);

.rounded {
  @for $i from 0 through 5 {
    @each $corner in $list-rounded {
      $infix: if($corner == 'null', '', '-#{$corner}');

      &#{$infix}{
        &-#{$i} {
          @if $corner == 'top' {
            border-top-left-radius: $i * .25rem !important;
            border-top-right-radius: $i * .25rem !important;
          } @else if $corner == 'right' {
            border-top-right-radius: $i * .25rem !important;
            border-bottom-right-radius: $i * .25rem !important;
          } @else if $corner == 'bottom' {
            border-bottom-left-radius: $i * .25rem !important;
            border-bottom-right-radius: $i * .25rem !important;
          } @else if $corner == 'left' {
            border-top-left-radius: $i * .25rem !important;
            border-bottom-left-radius: $i * .25rem !important;
          } @else {
            border-radius: $i * .25rem !important;
          }
        }

        &-default{
          border-radius: var(--rounded-default);
        }
      }
    }
  }
}
