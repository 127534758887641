$btn: (
  'colors': (
    'default': 'default',
    'secondary': 'default-secondary'
  ),
  'states': (
    'default': '',
    'outline': '-outline'
  )
);

.ant{
  &-btn{
    position: relative;
    color: $black;
    font: 400 $pxdefault $opensans, sans-serif;
    letter-spacing: rem(.5);
    box-shadow: none;
    border-radius: $rounded;
    cursor: pointer;

    &:hover,
    &:focus-visible{
      color: $black;
    }

    @each $name, $color in map-get($btn, 'colors') {
      @each $state, $state-class in map-get($btn, 'states') {
        &#{$state-class}-#{$name} {
          color: #{if($state == 'outline', var(--color-#{$color}), $white)};
          border-color: var(--color-#{$color});
          background-color: #{if($state == 'outline', 'transparent', var(--color-#{$color}))};

          &:hover,
          &:focus-visible {
            color: var(--color-#{$color}-text-hover);
            border-color: var(--color-#{$color}-hover);
            background-color: var(--color-#{$color}-hover);
          }

          &:disabled {
            opacity: .4;
            color: #{if($state == 'outline', var(--color-#{$color}), var(--color-#{$color}-text))};
            background-color: var(--color-#{$color});

            &:hover,
            &:focus-visible {
              color: var(--color-#{$color}-text);
              border-color: var(--color-#{$color});
              background-color: var(--color-#{$color});
            }
          }
        }
      }
    }

    &-transparent{
      border: 0;
      background-color: transparent;

      &:hover,
      &:focus-visible{
        background-color: rgba($default, .4);
      }

      &-danger{
        &:hover,
        &:focus-visible{
          background-color: rgba($red, .4);
        }
      }
    }

    &-expand{
      padding-left: 0;
      border: 0;
      background-color: transparent;

      &::after{
        width: rem(40);
        height: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: rem(40);
        background-color: rgba($default, .4);
        transition: width .3s;
        z-index: -1;
      }

      .fa{
        width: rem(40);
        display: inline-flex;
        justify-content: center;
      }

      &:hover,
      &:focus-visible{
        background-color: transparent;

        &::after{
          width: 100%;
        }
      }
    }

    &-filter{
      height: auto;
      padding: rem(30) rem(60);
      font-weight: bold;
      text-transform: uppercase;
      border: 0;
      box-shadow: 0 rem(1) rem(2) 0 rgba($black, .1);

      &:hover,
      &:focus{
      }
    }
  }
}
