.home {
  .btn {
    &-home {
      width: 100%;
      min-height: rem(170);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $default-bg-text;
      font-weight: bold;
      font-size: 130%;
      letter-spacing: rem(5);
      border: 0;
      background-color: $default-bg-card;
      box-shadow: 0 rem(1) rem(2) 0 rgba($black, 0.03),
        0 rem(1) rem(6) rem(-1) rgba($black, 0.02),
        0 rem(2) rem(4) 0 rgba($black, 0.02);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: $rounded;
        background-image: linear-gradient(
          to top,
          rgba($black, 0.05) 10%,
          rgba($black, 0)
        );
      }

      &:hover,
      &:focus-visible {
        color: $default-bg-text;
        background-color: $default-bg-card;
        transform: scale(1.03);
      }
    }
  }
}

.card {
  margin: auto;
  height: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
}
.ant-modal-close {
  width: 25px;
  height: 25px;
}
