.ant{
  &-card{
    min-height: 100%;
    color: $default-bg-text;
    border: none;
    border-radius: $rounded;
    background-color: $default-bg-card;

    &-head{
      border-bottom: 0;
      background-color: rgba($default-rgb, .3);
    }

    &-body{
      padding: rem(10);
    }

    &-widget{
    }
  }
}
