.ant{
  &-modal{
    padding: rem(20) 0;

    &-mask{
      backdrop-filter: blur(rem(3));
    }

    &-content{
      padding: 0;
      color: $default-bg-text;
      border-radius: $rounded;
      background-color: $default-bg-card;
    }

    &-footer{
      margin: 0;
      border: 0;
      text-align: center;
    }

    &-form{
      .ant-form-item{
        margin-bottom: rem(20);
      }
    }
  }
}
