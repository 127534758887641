$display: (
  inline: inline,
  inline-block: inline-block,
  block: block,
  table: table,
  table-cell: table-cell,
  flex: flex,
  inline-flex: inline-flex,
  none: none
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}{
      @each $class, $value in $display {
        &-#{$class} {
          display: $value !important;
        }
      }
    }
  }
}
