@mixin sizing($a, $b, $c, $d, $e){
  @each $breakpoint in map-keys($grid-breakpoints){
    @include media-breakpoint-up($breakpoint){
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      $sizes: $a;

      @for $i from 0 + 1 through $b{
        $sizes: append($sizes, $i*$c);
      }

      @each $prop, $abbrev in ($d){
        @each $size in $sizes{
          @if $size != ''{
            .#{$abbrev}#{$infix}-#{$size}{
              @if type-of($size) == 'number'{
                @if $e == 'px'{
                  #{$prop}: rem($size) !important;
                } @else {
                  #{$prop}: #{$size + $e} !important;
                }
              } @else {
                #{$prop}: $size !important;
              }
            }
          }
        }
      }
    }
  }
}

// width % + height % - 0 à 100 tranche de 25
@include sizing((auto, initial, 0), 4, 25, (width: w, height: h), '%');

// max-width % - 0 à 100 tranche de 25
@include sizing((auto, initial, 0), 4, 25, (max-width: mw), '%');

// min-height % - 0 à 100 tranche de 25
@include sizing((auto, initial, 0), 4, 25, (min-height: min-h), '%');

// height vh - 0 à 100 tranche de 5
@include sizing('', 20, 5, (height: hv), 'vh');

// width + height px à rem - 0 à 500 tranche de 50
@include sizing('', 10, 25, (width: wpx, height: hpx), 'px');
