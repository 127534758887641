.ant{
  &-breadcrumb{
    color: $default-bg-text;

    &-wrap{
      padding: rem(5) rem(10);
      border-radius: rem(4);
      background-color: $white;
    }

    &-separator{
      color: rgba($default-bg-text-rgb, .5);
    }

    &-link{
      color: $default-bg-text;
      transition: all .3s;

      &:hover,
      &:focus-visible{
        &:not(span){
          color: $default-bg-text;
          background-color: rgba($default-hover-rgb, .5);
        }
      }
    }
  }
}
